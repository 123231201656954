import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/_services/auth.service';


@Component({
  selector: 'google-callback-session',
  templateUrl: './google-callback-session.component.html',
  styleUrls: ['./google-callback-session.component.scss'],
})
export class GoogleCallbackSessionComponent implements OnInit {
sessionId: string;
  constructor(private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const currentDomain = "63bfcca765dc3f3863af755c";
    this.activatedRoute.queryParams.subscribe(paramsId => {
      if(this.activatedRoute.snapshot.queryParams['sessionid']) {
      this.sessionId = paramsId.sessionid;
    this.authService.tokens(this.sessionId).subscribe((x: any) => {
      return this.router.navigate(['/']);
    },
    (error) => {
      return this.router.navigate(['/']);
    })
  }
  if(this.activatedRoute.snapshot.queryParams['error']){
    return this.router.navigate([`/client/domain/${currentDomain}/${paramsId.error}`]);
  
  }
  return this.router.navigate(['/']);
  });
  }
}
