import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ValueTypeTransformPipe } from './_pipes/value-type-transform.pipe';
import { DynamicInputsComponent } from './_components/dynamic-inputs/dynamic-inputs.component';
import { CustomCheckboxComponent } from './_components/custom-checkbox/custom-checkbox.component';
import { ChooseMediaComponent } from './_components/choose-media/choose-media.component';
import { BfChipsComponent } from './_components/bf-chips/bf-chips.component';
import { MinutesToHours } from './_pipes/minutes-to-hours.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SafePipe } from './_pipes/safe.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatSliderModule } from '@angular/material/slider';
import { ScrolledToDirective } from './_directives/scrolled-to.directive';
import { DoubleRowPipe } from './_pipes/double-row.pipe';
import { LoadingProgressBarComponent } from './_components/loading-progress-bar/loading-progress-bar.component';
import { FilterFieldComponent } from './_components/filter-field/filter-field.component';
import { IsCheckedFieldPipe } from './_pipes/is-checked-field.pipe';
import { GetRoomGuestsPipe } from './_pipes/get-room-guests.pipe';
import { WarningModalComponent } from './_components/warning-modal/warning-modal.component';
import { FilterInputDirective } from './_directives/filter-input.directive';
import { CeilNumberPipe } from './_pipes/ceil-number.pipe';
import { DialogModule } from 'primeng/dialog';
import { KeyValuePairPipe } from './_pipes/key-value-pair.pipe';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { CustomLoaderComponent } from './_components/custom-loader/custom-loader.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true,
};

@NgModule({
  imports: [
    NgxIntlTelInputModule,
    DialogModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    NgbDropdownModule,
    MatFormFieldModule,
    MatInputModule,
    NgxQRCodeModule,
    LazyLoadImageModule,
    FormsModule,
  ],
  exports: [
    CustomLoaderComponent,
    NgxIntlTelInputModule,
    DialogModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    DirectivesModule,
    MatFormFieldModule,
    MatInputModule,
    ValueTypeTransformPipe,
    DynamicInputsComponent,
    CustomCheckboxComponent,
    ChooseMediaComponent,
    BfChipsComponent,
    MinutesToHours,
    SafePipe,
    ScrolledToDirective,
    FilterInputDirective,
    DoubleRowPipe,
    LoadingProgressBarComponent,
    FilterFieldComponent,
    GetRoomGuestsPipe,
    CeilNumberPipe,
    KeyValuePairPipe,
  ],
  declarations: [
    CustomLoaderComponent,
    KeyValuePairPipe,
    FilterInputDirective,
    ValueTypeTransformPipe,
    DynamicInputsComponent,
    DynamicInputsComponent,
    CustomCheckboxComponent,
    ChooseMediaComponent,
    BfChipsComponent,
    MinutesToHours,
    SafePipe,
    SafePipe,
    ScrolledToDirective,
    DoubleRowPipe,
    LoadingProgressBarComponent,
    WarningModalComponent,
    FilterFieldComponent,
    IsCheckedFieldPipe,
    GetRoomGuestsPipe,
    CeilNumberPipe,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule {}
