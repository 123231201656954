import { Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-regulation-card',
  standalone: true,
  imports: [NgOptimizedImage],
  templateUrl: './regulation-card.component.html',
  styleUrls: ['./regulation-card.component.scss'],
})
export class RegulationCardComponent {}
