import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private static readonly CACHE_EXPIRATION_MS = 92000; // 92 seconds in milliseconds
  private cache = new Map<
    string,
    { response: HttpResponse<any>; timestamp: number }
  >();

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cacheKey = this.buildCacheKey(req);
    const shouldCache = req.headers.get('Cache-Control') === 'true';

    if (shouldCache) {
      const cachedEntry = this.cache.get(cacheKey);

      if (cachedEntry) {
        const isExpired =
          Date.now() - cachedEntry.timestamp >
          CachingInterceptor.CACHE_EXPIRATION_MS;

        if (!isExpired) {
          return of(cachedEntry.response);
        }
      }
    }

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && shouldCache) {
          this.cache.set(cacheKey, { response: event, timestamp: Date.now() });
        }
      })
    );
  }

  private buildCacheKey(req: HttpRequest<any>): string {
    const bodyStr = req.method === 'POST' ? JSON.stringify(req.body) : '';
    return `${req.method}:${req.urlWithParams}:${bodyStr}`;
  }
}
