<!-- giveaway-footer.component.html -->
<div class="footer">
  <div class="max-width-wrapper">
    <div class="container-footer d-flex justify-content-between">
      <a href="/">
        <img
          src="assets/Bestinform_logo.png"
          alt="Bestinform Logo"
          width="200px" />
      </a>
      <app-regulation-card class="d-none d-lg-block" />
    </div>
    <hr class="width" />
    <!-- Menu Links Section -->
    <div class="menu-links">
      <div class="menu-items">
        <span *ngIf="!isMobile" class="menu-item" target="_blank"
          >© 2024 Bestinform
        </span>

        <a class="menu-item" href="/content/privacy-policy" target="_blank"
          >Politica de confidentialitate</a
        >
        <a
          class="menu-item"
          href="/content/terms-and-conditions"
          target="_blank"
          >Termeni si conditii</a
        >
      </div>
      <div class="social-media-icons">
        <a
          href="https://www.facebook.com/profile.php?id=100076116217462"
          target="_blank">
          <i class="fab fa-facebook"></i>
        </a>
        <a href="https://www.instagram.com/bestinform.eu" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a href="https://www.tiktok.com/@bestinform" target="_blank">
          <i class="fab fa-tiktok"></i>
        </a>
      </div>
    </div>

    <app-regulation-card
      *ngIf="isMobile"
      style="width: max-content; align-self: center" />

    <span
      *ngIf="isMobile"
      class="menu-item"
      style="text-align: center"
      target="_blank"
      >© 2024 Bestinform
    </span>
  </div>
</div>
