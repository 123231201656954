import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languageSubject = new BehaviorSubject<string>(navigator.language);
  public language$ = this.languageSubject.asObservable();

    constructor() {
        this.detectLanguageChange();
    }

    detectLanguageChange() {
        window.addEventListener('languagechange', () => {
            const newLanguage = navigator.language;
            this.languageSubject.next(newLanguage);
        });
    }
}
