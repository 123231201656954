export const environment = {
  ratehawkSandboxMode: true,
  appVersion: require('../../package.json').version,
  recaptcha: {
    siteKey: '6Ldn1F0oAAAAAGsXgMLrKkl6i-16owTvR8TQ4vXR',
  },
  production: true,
  url: '',
  api_url: 'https://bestinform.eu',
  contextPath: 'bestinform',
  stripePublicKey:
    'pk_live_51P3aQlEZiUKGHZHXnNpgpUNxqr1mPbSNmEniF2RLqa1Ek7JZ7klkEdaws0wqHfFyleaXT1my5tPrQkaXGp8Bji1G00iS0MRcXj',
  pusher: {
    key: '10fc42d9363ab9ebe512',
    cluster: 'eu',
  },
  cloudinary: {
    cloudName: 'dxiwo82kq',
    preset: 'bestinform',
  },
  googleClientId:
    '61957648174-pup3vp8u9ba8bdh223lamnfduo3ears7.apps.googleusercontent.com',
  googleTagMangerId: 'GTM-PX6CLV5N',
  privateKey: 'X57wvWBWKxYMIPXYGAU/zG/UED9myGpZZvslfANCYkc=',
  privateIv: 'UBo+LJR9AFCkgbfxmfcZbA==',
};
