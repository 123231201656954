import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DesignResponsiveService implements OnDestroy {
  private readonly mobileBreakpoint: string = '(max-width: 991px)';
  private readonly isMobileSubject = new BehaviorSubject<boolean>(false);

  private destroy$ = new Subject<void>();

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(this.mobileBreakpoint)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: BreakpointState) => {
        this.isMobileSubject.next(result.breakpoints[this.mobileBreakpoint]);
      });
  }

  get isMobile(): boolean {
    return this.isMobileSubject.value;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
