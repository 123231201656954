import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpEncryptionInterceptor implements HttpInterceptor {
  privateKey = crypto.enc.Base64.parse(environment.privateKey);
  privateIv = crypto.enc.Base64.parse(environment.privateIv);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modifiedReq = req;
    if (
      req.body &&
      typeof req.body === 'object' &&
      !(req.body instanceof FormData)
    ) {
      const encryptedBody = this.getEncryptedPayload(req.body);
      modifiedReq = req.clone({
        body: { data: encryptedBody },
        setHeaders: { 'X-Encrypted': 'true' },
      });
    }

    return next.handle(modifiedReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body?.data) {
          return this.getDecryptedBody(event) as HttpEvent<any>;
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error?.data) {
          error = this.getDecryptedBody(error) as HttpErrorResponse;
        }
        throw error;
      })
    );
  }

  private getDecryptedBody(
    response: HttpResponse<any> | HttpErrorResponse
  ): HttpEvent<any> | HttpErrorResponse {
    const body =
      response instanceof HttpErrorResponse ? response.error : response.body;

    try {
      const encryptedBytes = crypto.enc.Base64.parse(body.data);
      const cipherParams = crypto.lib.CipherParams.create({
        ciphertext: encryptedBytes,
        iv: this.privateIv,
        key: this.privateKey,
        algorithm: crypto.algo.AES,
        padding: crypto.pad.Pkcs7,
      });

      const decryptedBytes = crypto.AES.decrypt(cipherParams, this.privateKey, {
        iv: this.privateIv,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
      });

      const decryptedData = decryptedBytes.toString(crypto.enc.Utf8);
      if (response instanceof HttpResponse) {
        return response.clone({ body: JSON.parse(decryptedData) });
      } else {
        response = { ...response, error: JSON.parse(decryptedData) };
        return response;
      }
    } catch (error) {
      return response;
    }
  }

  private getEncryptedPayload(payload: any): string {
    const payloadString = JSON.stringify(payload);
    const encrypted = crypto.AES.encrypt(payloadString, this.privateKey, {
      iv: this.privateIv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    });

    return encrypted.ciphertext.toString(crypto.enc.Base64);
  }
}
