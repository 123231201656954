import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StorageNameElement } from '@shared/_models/storage.model';
import { ConfirmLocationComponent } from '@standalone-components/confirm-location/confirm-location.component';
import {
  BehaviorSubject,
  firstValueFrom,
  lastValueFrom,
  Observable,
  take,
} from 'rxjs';
import { City } from '../_models/city.model';
import { CurrentSubscription } from '../_models/current-subscription.model';
import { GenericPagination } from '../_models/generic-pagination.model';
import { User } from '../_models/user.model';
import { ToastService } from './toast.service';

export type SubscriptionFilters = {
  subscriptionId?: string;
  status?: string;
  userId?: string;
  purchasedDate?: string;
  expirationDate?: string;
  stripeSubscriptionId?: string;
};

export class FormattedNumber {
  countryCode: string;
  internationalNumber: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userLoggedIn = new BehaviorSubject<User>(null);
  userLoggedInObs = this.userLoggedIn.asObservable();

  private currentUser: User;

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly translate: TranslateService
  ) {}

  baseApiUrl = '/bestinform/uploadUserContract';
  baseCoverImgApiUrl = '/bestinform/uploadUserCoverImage';
  baseAvatarImgApiUrl = '/bestinform/uploadAvatar';

  async triggerUserLoggedIn() {
    const user = await this.getCurrentUserAsPromise();
    this.userLoggedIn.next(user);
    this.checkUserCoordinates();
  }

  checkUserCoordinates() {
    let detectedCity: City = null;

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.findClosestCity(
            position.coords.latitude,
            position.coords.longitude
          ).then((city) => {
            detectedCity = city;
            if (
              !this.currentUser.city ||
              this.currentUser.city !== detectedCity.name ||
              this.currentUser.country !== detectedCity.country
            ) {
              const modalRef = this.ngbModal.open(ConfirmLocationComponent, {
                centered: true,
                size: 'sm',
                backdrop: 'static',
              });
              modalRef.componentInstance.detectedCity = detectedCity;
              modalRef.componentInstance.hasCity = this.currentUser.city;
              modalRef.closed.pipe(take(1)).subscribe({
                next: (res) => {
                  this.updateCurrentUser({
                    ...this.currentUser,
                    city: res?.location,
                    country: res?.country,
                    currentGeographicalCoordinates:
                      res?.geographicalCoordinates,
                  })
                    .pipe(take(1))
                    .subscribe({
                      error: () => {
                        this.toastService.showToast(
                          this.translate.instant('TOAST.ERROR'),
                          this.translate.instant('TOAST.SERVER-ERROR'),
                          'error'
                        );
                      },
                    });
                },
              });
            }
          });
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            console.log('Location permission denied');
          }
        }
      );
    }

    const permissionStatus = navigator?.permissions?.query({
      name: 'geolocation',
    });

    console.log('permission status', permissionStatus);
  }

  private findClosestCity(latitude: number, longitude: number): Promise<City> {
    return lastValueFrom(
      this.httpClient.post<City>(
        '/bestinform/findClosestCity?latitude=' +
          latitude +
          '&longitude=' +
          longitude,
        {}
      )
    );
  }

  formatUserTelephoneToSave(fullNumber: FormattedNumber): string {
    return fullNumber.countryCode + ' ' + fullNumber.internationalNumber;
  }

  formatUserTelephoneToRetrieve(fullNumber: string): FormattedNumber {
    const telephoneSplit = fullNumber.split(' ');
    if (telephoneSplit.length > 2) {
      return {
        internationalNumber: telephoneSplit.slice(2).join(' '),
        countryCode: telephoneSplit[0].toLocaleLowerCase(),
      };
    }

    return {
      internationalNumber: fullNumber,
      countryCode: 'ro',
    };
  }

  //upload contract for any user id
  uploadUserContract(userId: string, file: string | Blob): Observable<object> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('file', file);

    // Make http post request over api
    // with formData as req
    return this.httpClient.post(
      this.baseApiUrl + '?userId=' + userId,
      formData
    );
  }

  //upload contract for current user
  uploadCurrentUserContract(file: string | Blob): Observable<any> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('file', file);

    // Make http post request over api
    // with formData as req
    return this.httpClient.post(this.baseApiUrl, formData);
  }

  uploadUserCoverImage(coverFile: string | Blob): Observable<any> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('coverFile', coverFile);

    // Make http post request over api
    // with formData as req
    return this.httpClient.post(this.baseCoverImgApiUrl, formData);
  }

  uploadUserAvatar(file: string | Blob): Observable<any> {
    // Create form data
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append('file', file);

    // Make http post request over api
    // with formData as req
    return this.httpClient.post(this.baseAvatarImgApiUrl, formData);
  }

  addUser(user: object) {
    return this.httpClient.post('/bestinform/addUser', user);
  }

  getJWTToken() {
    return localStorage.getItem('token');
  }

  getCurrentUser(): Observable<User> {
    return this.httpClient.get<User>('/bestinform/getCurrentUser');
  }

  async getCurrentUserAsPromise(refreshUser: boolean = false): Promise<User> {
    if (!this.currentUser || refreshUser) {
      try {
        const user = await firstValueFrom(
          this.httpClient.get<User>('/bestinform/getCurrentUser')
        );
        this.currentUser = user;
        return user;
      } catch (error) {
        console.error('Get current user: An error occurred:', error);
        return Promise.resolve(null);
      }
    }

    return Promise.resolve(this.currentUser);
  }

  isLoggedIn(): boolean {
    return this.currentUser !== null && this.currentUser !== undefined;
  }

  get currentUserId(): string {
    return this.currentUser?.id;
  }

  get isAdmin(): boolean {
    return (
      this.isLoggedIn() && this.currentUser.roles.includes('ROLE_SUPER_ADMIN')
    );
  }

  get isStaff(): boolean {
    return this.isLoggedIn() && this.currentUser.roles.includes('ROLE_STAFF');
  }

  async logout(): Promise<void> {
    await firstValueFrom(
      this.httpClient.post('/bestinform/logoutUser', {})
    ).then(() => {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem(StorageNameElement.CurrentLanguage, 'ro');
      this.currentUser = null;
      this.userLoggedIn.next(null);
      this.router.navigate(['/']);
    });
  }

  getUserById(userId: string) {
    return this.httpClient.get('/bestinform/getUserById?userId=' + userId);
  }

  updateCurrentUser(user: object) {
    return this.httpClient.put('/bestinform/updateCurrentUser', user);
  }

  updateUser(userId: string, user: object) {
    return this.httpClient.put('/bestinform/updateUser?userId=' + userId, user);
  }

  deleteUser(userId: string) {
    return this.httpClient.delete('/bestinform/deleteUser?userId=' + userId);
  }

  uploadAvatar(image) {
    return this.httpClient.post('/bestinform/uploadAvatar', image);
  }

  deleteProfileImage() {
    return this.httpClient.put('/bestinform/deleteProfileImage', {});
  }

  changeUserStatus(userId: string, approvedStatus: string) {
    return this.httpClient.put(
      '/bestinform/changeUserStatus?userId=' +
        userId +
        '&approvedStatus=' +
        approvedStatus,
      {}
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.httpClient.put('/bestinform/changePassword', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }

  updateUserProfile(user: object) {
    return this.httpClient.put('/bestinform/updateUserProfile', user);
  }

  sendRegistrationEmail(userId: string) {
    return this.httpClient.get(
      '/bestinform/public/sendRegistrationEmail?userId=' + userId
    );
  }

  getCurrentSetting() {
    return this.httpClient.get('/bestinform/getCurrentSetting');
  }

  updateCurrentSetting(setting: object) {
    return this.httpClient.put('/bestinform/updateCurrentSetting', setting);
  }

  executeRecurringPayments() {
    return this.httpClient.post('/bestinform/executeRecurringPayments', {});
  }

  changeActiveStatus(targetUserId: string, accept: boolean) {
    return this.httpClient.put(
      '/bestinform/changeActiveStatus?targetUserId=' +
        targetUserId +
        '&accept=' +
        accept,
      {}
    );
  }

  getAllCountries() {
    return this.httpClient.get<string[]>('/bestinform/getAllCountries');
  }

  listCityFiltered(
    page: number,
    size: number,
    filters: { name?: string; country?: string }
  ) {
    return this.httpClient.post<GenericPagination<City>>(
      '/bestinform/listCityFiltered?page=' + page + '&size=' + size,
      filters
    );
  }

  getCurrentPurchasedSubscription() {
    return this.httpClient.get<CurrentSubscription>(
      '/bestinform/getCurrentPurchasedSubscription'
    );
  }

  listPurchasedSubscriptionsFiltered(
    page: number,
    size: number,
    sort: string,
    dir: string,
    filters: SubscriptionFilters
  ) {
    return this.httpClient.post<GenericPagination<CurrentSubscription>>(
      '/bestinform/listPurchasedSubscriptionsFiltered?page=' +
        page +
        '&size=' +
        size +
        '&sort=' +
        sort +
        '&dir=' +
        dir,
      filters
    );
  }

  cancelSubscription(subId: string) {
    return this.httpClient.post<{ success: boolean; reason: string }>(
      '/bestinform/cancelSubscription?purchasedSubscriptionId=' + subId,
      {}
    );
  }

  makeAutoRenewTrue(subscriptionId: string) {
    return this.httpClient.put(
      '/bestinform/makeAutoRenewTrue?purchasedSubscriptionId=' + subscriptionId,
      {}
    );
  }
}
