<div
  id="app"
  class="app"
  [dir]="settings.rtl ? 'rtl' : 'ltr'"
  [ngClass]="[settings.theme, 'toolbar-' + settings.toolbar]"
  [class.main-toolbar-fixed]="settings.mainToolbarFixed">
  <!-- Toaster -->
  <app-toaster></app-toaster>
  <div class="app-content" *ngIf="!isLoading">
    <router-outlet></router-outlet>
  </div>

  <app-custom-loader *ngIf="isLoading" />
</div>
