<div class="regulation">
  <div class="regulation__images">
    <img
      src="assets/images/regulations/european-union.png"
      alt="regulator logo"
      height="70" />
    <img
      src="assets/images/regulations/instrumente.png"
      alt="regulator logo"
      height="70" />
    <img
      src="assets/images/regulations/romania-gov.png"
      alt="regulator logo"
      height="70" />
  </div>
  <a href="https://www.oipsi.gov.ro" target="_blank" class="regulation__link"
    >www.oipsi.gov.ro</a
  >
</div>
