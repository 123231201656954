// google-tag-manager.service.ts
import { Injectable } from '@angular/core';
import { GoogleTagEvent } from '@shared/_models/googleTagManager.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor() {
    this.initializeGTM();
  }

  private initializeGTM() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${environment.googleTagMangerId}`;
    document.head.appendChild(script);

    window['dataLayer'] = window['dataLayer'] || [];
  }

  sendGTMStartEvent(): void {
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  sendReservationEvent(
    reservationType: GoogleTagEvent,
    transactionTotal: number,
    transactionId: string,
    customerEmail: string,
    customerPhone: string,
    customerCurrency: string = 'EUR'
  ): void {
    window['dataLayer'].push({
      event: reservationType,
      transactionId: transactionId,
      transactionTotal: transactionTotal,
      currency: customerCurrency,
      customerEmail: customerEmail,
      customerPhone: customerPhone,
    });
  }
}
