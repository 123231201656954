import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from '@shared/_services/google-tag-manager.service';
import { LanguageService } from '@shared/_services/language.service';
import { UserService } from '@shared/_services/userData.service';
import { Subject, takeUntil } from 'rxjs';
import { AppSettings, Settings } from './app.settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean = true;
  settings: Settings;
  private destroy$ = new Subject<void>();

  constructor(
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly appSettings: AppSettings,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    private readonly translate: TranslateService,
    private readonly _registry: MatIconRegistry,
    private readonly _sanitize: DomSanitizer,
    private readonly languageService: LanguageService,
    private readonly userService: UserService
  ) {
    this.settings = this.appSettings.settings;
    translate.addLangs(['en', 'de', 'fr', 'ru', 'tr', 'ro']);
  }

  async ngOnInit() {
    this.googleTagManagerService.sendGTMStartEvent();

    this.registerMaterialIcons();

    const colorMode = localStorage.getItem('colorMode') || 'light';
    localStorage.setItem('colorMode', colorMode);
    document.documentElement.setAttribute('data-theme', colorMode);

    this.languageService.language$
      .pipe(takeUntil(this.destroy$))
      .subscribe((lang) => {
        this.translate.use(
          this.translate.getLangs().includes(lang) ? lang : 'ro'
        );
      });

    this.userService
      .getCurrentUserAsPromise()
      .finally(() => (this.isLoading = false));
  }

  ngAfterViewInit() {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      this.renderer.addClass(preloader, 'hide');
    }

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.settings.mainToolbarFixed = false;
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private registerMaterialIcons(): void {
    const icons = [
      { name: 'globe', path: '/assets/icons/globe.svg' },
      { name: 'map-pin', path: '/assets/icons/map-pin.svg' },
      {
        name: 'search_person_icon',
        path: '/assets/images/others/homepage/search_person_icon.svg',
      },
      { name: 'guests', path: '/assets/icons/guests.svg' },
      { name: 'plane-in', path: '/assets/icons/plane-in.svg' },
      { name: 'plane-out', path: '/assets/icons/plane-out.svg' },
    ];

    icons.forEach((icon) =>
      this._registry.addSvgIcon(
        icon.name,
        this._sanitize.bypassSecurityTrustResourceUrl(icon.path)
      )
    );
  }
}
